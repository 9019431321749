import {Component} from 'react';
import LargeItem from '../components/LargeItem';

export interface Product{
	id: number;
	name: string;
	price: number;
	thc: number;
	net_wt: number;
	img: string;
	stock: number;
}

interface LandingProps{

}

interface LandingState{
	loading: boolean;
	products: Product[],
}

class Landing extends Component<LandingProps, LandingState> {
	constructor(props: LandingProps){
		super(props);
		this.state = {
			loading: true,
			products: [],
		}
	}

	componentDidMount(){
		fetch('https://www.jbs.fun/api.php', {
			mode: 'cors',
			method: 'GET',
		}).then((res)=>res.json()).then((data)=>{
			console.log(data);
			this.setState({
				products: data.products,
				loading: false,
			});
		});
	}

	/**
	 * React Render
	 * @return {React.ComponentElement}
	 */
	render() {
		const {loading, products} = this.state;

		return loading ? <div className="text-center py-10 text-green-600">Loading Product List...</div> :
			<div id="item-list" className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-10 xl:gap-10">
				{products.map((i)=><LargeItem key={i.id} {...i} />)}
			</div>;
	}
}

export default Landing;
