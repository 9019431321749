import {Component} from 'react';
import LargeItem from '../components/LargeItem';
import {Product} from './Landing';

interface AdminProps{

}

interface AdminState{
	isLoggedIn: boolean;
	password: string;
	products: Product[];
}

class AdminPanel extends Component<AdminProps, AdminState> {
	constructor(props: AdminProps){
		super(props);

		this.state = {
			isLoggedIn: false,
			password: '',
			products: [],
		}

		this.AttemptLogin = this.AttemptLogin.bind(this);
	}

	AttemptLogin(e: React.MouseEvent<HTMLButtonElement, MouseEvent>){
		e.preventDefault();
		const {password} = this.state;

		if(password === 'sickkids45'){
			fetch('https://www.jbs.fun/api.php', {
				mode: 'cors',
				method: 'GET',
			}).then((res)=>res.json()).then((data)=>{
				this.setState({
					isLoggedIn: true,
					products: data.products,
				});
			});
		}
	}
	/**
	 * React Render
	 * @return {React.ComponentElement}
	 */
	render() {
		const {password, isLoggedIn, products} = this.state;

		if(isLoggedIn){
			return <>
				<div id="item-list" className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-10 xl:gap-10">
					{products.map((item)=><LargeItem {...item} editable />)}
				</div>
			</>;
		}


		return <div className="text-center py-24">
			<label className="block mb-2">
				<span className="block">Management</span>
				<input type="password" name="ad-password" className="px-2 py-1 border-gray-600 border rounded" value={password} onChange={(e)=>{
					this.setState({
						password: e.currentTarget.value,
					});
				}} />
			</label>
			<button className="bg-green-800 px-8 py-2 text-white rounded" onClick={this.AttemptLogin}>Login</button>
		</div>;
	}
}

export default AdminPanel;
