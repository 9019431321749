import {Component} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import AdminPanel from './pages/Admin';
import Landing from './pages/Landing';

class App extends Component {
	/**
	 * React Render
	 * @return {React.ComponentElement}
	 */
	render() {
		return <div className="container mx-auto">
			<header className="text-center py-5">
				<h1 className="text-2xl text-gray-800">AD Edibles Catalog</h1>
				<p>Text your order requests to <a href="sms:2898066145">(289) 806-6145</a>. We require photo ID upon delivery.</p>
			</header>
			<Router>
				<Route path="/" exact component={Landing} />
				<Route path="/admin" exact component={AdminPanel} />
			</Router>
			<footer className="text-center">
				&copy; AD Edibles 2021 | All Rights Reserved
			</footer>
		</div>;
	}
}

export default App;
