import {Component} from 'react';
interface ItemProps{
	id: number;
	name: string;
	price: number;
	img: string;
	net_wt: number;
	thc: number;
	stock: number;
	editable?: boolean;
}

interface ItemState{
	stock: number;
}


class LargeItem extends Component<ItemProps, ItemState> {
	constructor(props: ItemProps){
		super(props);

		this.state = {
			stock: props.stock,
		};
	}

	/**
	 * React Render
	 * @return {React.ComponentElement}
	 */
	render() {
		const {id, name, price, img, thc, net_wt, editable} = this.props;
		const {stock} = this.state;

		return <div className="w-full">
			<div className="flex flex-col sm:flex-row items-center border border-grey-dark group shadow-none hover:shadow-lg rounde transition-shadow">
				<div className="w-full sm:w-2/5 lg:w-5/11 relative">
					<div className="relative rounded-l">
						<div className="w-full h-64 bg-center bg-no-repeat bg-cover" style={{
							backgroundImage: `url(${img})`,
						}}>
						</div>
						<span className="absolute top-0 right-0 text-white bg-green-600 px-5 py-1 mt-4 mr-4 rounded-full font-hk font-bold  text-v-green text-sm uppercase tracking-wide">New</span>
						{/* <div className="absolute opacity-0 group-hover:opacity-100 flex justify-center items-center py-28 inset-0 group hover:shadow-lg transition-all bg-secondary bg-opacity-85">
							<a href="/cart" className="bg-white hover:bg-primary-light rounded-full px-3 py-3 flex items-center transition-all mr-3">
								<img src="https://d33wubrfki0l68.cloudfront.net/16f4de05841e1eea2fbe536d4053b73f0ad85baf/77013/assets/img/icons/icon-cart.svg " className="h-6 w-6" alt="icon cart" />
							</a>
							<a href="/product" className="bg-white hover:bg-primary-light rounded-full px-3 py-3 flex items-center transition-all mr-3">
								<img src="https://d33wubrfki0l68.cloudfront.net/56f050a65973a419ab0f192614c9a3c7232604d1/4b447/assets/img/icons/icon-search.svg" className="h-6 w-6" alt="icon search" />
							</a>
							<a href="/account/wishlist/" className="bg-white hover:bg-primary-light  rounded-full px-3 py-3 flex items-center transition-all">
								<img src="https://d33wubrfki0l68.cloudfront.net/f7c995473e0c29c1578cd00a2b7baa1562456ad9/b584a/assets/img/icons/icon-heart.svg" className="h-6 w-6" alt="icon heart" />
							</a>
						</div> */}
					</div>
				</div>
				<div className="w-full sm:w-3/5 lg:w-6/11 px-6 py-6 sm:py-0">
					<h3 className="font-hk text-xl xl:text-2xl text-grey-darkest">{name}</h3>
					<span className="font-hk font-bold text-secondary text-xl block pt-1">${price.toFixed(2)}</span>
					{stock > 0 ? <span className="pt-4 font-hk font-bold text-green-500 text-base block">In Stock ({stock})</span> : <span className="pt-4 font-hk font-bold text-red-500 text-base block">Out Of Stock</span>}
					{editable && <><input type="number" inputMode="numeric" value={stock} className="py-1 px-2 border-gray-600 border rounded rounded-r-none" onChange={(e)=>{
						this.setState({
							stock: parseInt(e.currentTarget.value),
						});
					}} /><button className="bg-green-700 rounded rounded-l-none py-1 px-3 border border-green-800 text-white" onClick={()=>{
						fetch('https://www.jbs.fun/api.php?action=save', {
							mode: 'cors',
							method: 'POST',
							body: JSON.stringify({
								id,
								stock
							})
						}).then((res)=>res.json()).then((data)=>{
							if(data.success){
								alert('Saved');
							}
						});
					}}>Save</button></>}
					{/* <p className="font-hk text-grey-darkest pt-2 text-sm xl:text-base">Elyssi sunglasses provides a new way to protect your eyes from the sun’s UV light, because of its polarized lenses.</p> */}
					<div className="flex items-center pt-3 xl:pt-5">
						{/* <div className="flex items-center">
							<i className="bx bxs-star text-primary"></i>
							<i className="bx bxs-star text-primary"></i>
							<i className="bx bxs-star text-primary"></i>
							<i className="bx bxs-star text-primary"></i>
							<i className="bx bxs-star text-primary"></i>
						</div> */}
						<p className="font-hk text-sm text-secondary ml-2"><b>THC</b>: {thc}mg</p>
						{net_wt > 0 && <p className="font-hk text-sm text-secondary ml-2"><b>NET WT</b>: {net_wt}g</p>}
					</div>
				</div>
			</div>
		</div>;
	}
}

export default LargeItem;
